header.header-fields {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
}

header.header-fields h3 {
  margin: 0;
  font-size: 16px;
}

header.header-fields .info-btn-action {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;

  background-color: #16a34a;
  color: white;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

header.infos-header .infos-buttons .info-btn-action:hover {
  background-color: #15803d;
}

@media (max-width: 712px) {
  header.infos-header {
    flex-direction: column;
    gap: 16px;
  }

  header.infos-header .infos-buttons {
    flex-direction: column;
  }
}
