.msgList {
  height: 30rem;
  max-height: 30rem;
  overflow-y: scroll;
}

.msgList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.msgList::-webkit-scrollbar-track {
  background: #f1f5f9;
}

.msgList::-webkit-scrollbar-thumb {
  background: #475569;
  border-radius: 4px;
}

.msgList::-webkit-scrollbar-thumb:hover {
  background: #334155;
}

.msgContent {
  width: fit-content;
  padding: 8px 16px;
  margin-top: 8px;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  border-radius: 8px;
  border-bottom-right-radius: 0;
  background-color: #cbd5e1;
  color: #334155;
}

.primaryMessage {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
  background-color: #613BCE;
  color: #ffffff;
  text-align: left;
}

.oddLine {
  display: flex;
  justify-content: right;
  margin-right: 8px;
}

.infosMessage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.msgBox {
  padding: 8px;
  border-color: #bbbb;
  border-radius: 3px;
}

.lineSpace {
  line-height: 24px;
}

/* CARD INFOS */

.mapContainer {
  height: 160px;
  border-radius: 8px;
  overflow: hidden;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentInfo header > span {
  display: block;
  font-size: 32px;
  margin-bottom: 6px;
}

.contentInfo header > div {
  display: flex;
  align-items: center;
  gap: 16px;
}

.contentInfo header > div strong {
  font-size: 16px;
}

.contentInfo header > div span {
  padding: 4px 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  width: fit-content;
}

.statusOpen {
  background-color: #fecaca;
  color: #dc2626;
}

.statusAttendance {
  background-color: #c7d2fe;
  color: #4f46e5;
}

.statusClosed {
  background-color: #a7f3d0;
  color: #059669;
}

.contentInfo > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.contentInfo > div span {
  font-size: 14px;
}

.contentInfo > div strong {
  font-size: 12px;
}

.advancedData {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.advancedData > div > span {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-weight: 400;
  color: #9a9a9a;
  display: inline-block;
}

.advancedData > div > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0;
  border-radius: 4px;
  text-transform: uppercase;
  border: 1px solid #94a3b8;
  background: transparent;
  color: #475569;
  font-weight: 500;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.advancedData > div > a:hover {
  background-color: #94a3b8;
  color: white;
}

/* MODAL STATUS */

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  background-color: #f1f5f9;
  border-radius: 6px;
  border: 1px solid transparent;
  cursor: pointer;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.cardContentActive {
  background-color: #bae6fd;
  border-color: #0284c7;
}

.cardContent div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.cardContent div span {
  font-size: 32px;
}

.cardContent div strong {
  font-size: 16px;
  color: #1e293b;
}

.cardDescription {
  flex-grow: 1;
}

.cardContent p {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #475569;
  flex-grow: 1;
}

.icon {
  display: flex;
  place-items: center;
  padding: 6px;
  border-radius: 9999px;
}

.red {
  background-color: #fecaca;
}

.indigo {
  background-color: #c7d2fe;
}

.green {
  background-color: #bbf7d0;
}

/* BADGE SEM CATEGORIAS */
.containerBadge {
  display: flex;
  align-items: center;
  gap: 6px;
}

.badge {
  padding: 4px;
  background-color: #fecaca;
  color: #dc2626;
  border-radius: 2px;

  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  font-weight: 500;
}
