.input-file-custom .form-group input {
  width: 100%;
  margin-top: 8px;
}

.content-enrolleds {
  height: 500px;
  overflow-y: auto;
}

.card-enrolleds {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  margin-bottom: 8px;
  border: 1px solid #ccc!important;
  border-radius: 4px;
}

.card-enrolleds-name {
  font-weight: 500;
  color: #1e293b;
}

.card-enrolleds-email {
  font-size: 12px;
  color: #64748b;
}

