.header-guide {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  margin-bottom: 50px;

  h3 {
    font-weight: 400;
  }
}

.content-start-guide {
  display: flex;
  flex-direction: column;
  align-items: center;

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 85%;

    .header {
      padding: 15px 15px;
    }

    .table-full-width {
      margin: 0;
    }

    .panel-group {
      margin: 0;
    }

    .panel-heading {
      background-color: #ffffff;
    }
  }
}

.accordion-header {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
}

.accordion-header .panel-title {
  font-weight: 600;
  color: #333333;
}

.accordion-header .panel-title:hover {
  color: #42d0ed;
}

.accordion-header .accordion-span {
  color: #333333;
}

.accordion-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;

  border-top: 1px solid #ddd;

  p {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
  }

  ol {
    margin-left: 35px;
  }

  .img-wrapper {
    width: 100%;
    background-color: #F8F3FF;
    padding: 35px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cover-img {
      width: 90%;
      border-radius: 8px;
    }
  }

  .body-card {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    
    .btn-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      
      .btn-action {
        height: 39px;
        padding: 8px 10px;
        border-radius: 8px;
        background-color: #663dc9;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.progress-bar {
  position: absolute;
  top: 5px;
  right: 100px;
}

.accordion-body::before,
.accordion-body::after {
  display: none;
}

.panel {
  margin-bottom: 8px;
}
