.personal-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.personal-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.personal-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.personal-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.personal-step .field-text {
  width: 100%;
  max-width: 456px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  padding: 0 12px;
  border: 2px solid #9747ff33;
  border-radius: 8px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.personal-step .field-text:focus-within {
  border-color: #9747ff;
}

.personal-step .field-text input {
  flex: 1;
  font-size: 18px;
  border: 0;
  background-color: transparent;
  color: #111827;
}

.personal-step .field-text input::placeholder {
  color: #9ca3af;
}

.personal-step > button {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.personal-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.personal-step button:not(:disabled):hover {
  background-color: #6f18bf;
}
