.iphone-x {
    position: relative;
    margin: 20px;
    width: 240px;
    height: 560px;
  
    overflow: hidden;

    border-radius: 40px;
    box-shadow: 
        0px 0px 0px 8px #1f1f1f, 
        0px 0px 0px 9px #191919,
        0px 0px 0px 10px #111;


  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before {
    top: 0px;
    width: 48%;
    height: 20px;
    background-color: #000000;
    border-radius: 0px 0px 40px 40px;
  }
}
.main-element{
  padding: 32px 16px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(12, 1fr);
  gap: 0.5rem;
  row-gap: 0.1rem;
}

.size-control{
  overflow: scroll;
  height: 580px;
}

.block-element{
    border-radius: 6px;
    border: 1px solid #cbd5e1;

    background-color: #f1f5f9;
    
    height: 7rem;
    width: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.block-element-selected{
  border-radius: 6px;
  border: 1px solid rgb(177, 203, 235);

  background-color: #d8ebff;

  cursor: not-allowed;
}
.block-element > div {
  display: flex;
  flex-direction: column;
  align-self: center;
  vertical-align: middle;
}

.content-form{
  flex-grow: 1;
  padding: 0 3rem;
}

.content-main{
  display: flex;
  width: 100%;
}

.overflow-elypsis{
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis;
  width: 100%;
}  

.selected-box {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 8px;

  /* height: 580px; */

  background-color:rgb(241, 245, 249);
  color:#475569;

  border-radius: 6px;
  font-weight: 500;
  form{
    width: 100%;
  }
}
.img-thumbnail{
  max-height: 100px;
  max-width: 200px;
}
.preview-group{
  width: fit-content;
  position: relative;
  .close-button{
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    background-color: white;
    border-radius: 9999px;
    padding: 3px;
    color: red;
  }
}