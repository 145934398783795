.fields-empty-state {
    width: 100%;
    padding: 64px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.fields-empty-state strong {
    font-size: 16px;
}

.fields-empty-state p {
    font-size: 14px;
    max-width: 500px;
    text-align: center;
}

.image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: flex-start;
}

.styled-image {
    width: 180px;
    border-radius: 10px;
    margin-bottom: 10px;
    object-fit: cover;
}