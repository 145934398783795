.link-for-app {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
}

.link-for-app span {
  font-size: 14px;
  color: #6138d0;
  font-weight: 500;
}

.link-for-app svg {
  color: #6138d0;
}
