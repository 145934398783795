.infos-empty-state {
  width: 100%;
  padding: 64px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.infos-empty-state strong {
  font-size: 16px;
}

.infos-empty-state p {
  font-size: 14px;
  max-width: 500px;
  text-align: center;
}