.circular-progress-bar {
  width: 60px;
  height: 60px;
  position: relative;
}

.circular-progress {
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 0.6s ease;
}

.percentage {
  fill: #333333;
  font-size: 7.5px;
  text-anchor: middle;
  dominant-baseline: middle;
}
