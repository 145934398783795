.profile-section {
  margin-bottom: 20px;
}

.profile-section h2 {
  font-size: 1.5em;
  color: #252525;
  margin: 0 0 20px 0;
}

.infos-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.infos-label {
  font-weight: bold;
}

.infos-value {
  color: #666;
  margin-left: 5px;
}
