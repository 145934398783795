.leads-page {
  .form-container {
    padding: 0px 8px;
  }

  .form-container form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-group {
    display: flex;
    gap: 15px;
  }

  .form-actions {
    margin-left: 50px;
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  .form-field {
    width: 350px;
  }

  .form-date {
    display: flex;
    flex-direction: column;
  }

  .form-button {
    width: 70px;
    height: 40px;
    border-radius: 4px;
    border: none;
    color: white;
    font-weight: 600;
  }
}
