.container-leaders {
  width: 100%;
  padding: 24px;
}

.container-leaders .content-list {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);

  display: flex;
  flex-direction: column;
}

.container-leaders .content-list header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.container-leaders .content-list header h3 {
  margin: 0;
  font-size: 16px;
}

.container-leaders .content-list header .top-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container-leaders .content-list header .top-header > div:first-child {
  display: flex;
  justify-content: space-between;
}

.container-leaders .content-list header .top-header .buttons-leaders {
  display: flex;
  align-items: center;
  gap: 8px;
}

.container-leaders
  .content-list
  header
  .top-header
  .buttons-leaders
  .btn-action {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;

  background-color: #16a34a;
  color: white;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.container-leaders
  .content-list
  header
  .top-header
  .buttons-leaders
  .btn-action.outline {
  background: transparent;
  border-color: #94a3b8;
  color: #475569;
}

.container-leaders
  .content-list
  header
  .top-header
  .buttons-leaders
  .btn-action:hover {
  color: white;
  background-color: #15803d;
}

.container-leaders
  .content-list
  header
  .buttons-leaders
  .btn-action.outline:hover {
  color: #475569;
  background-color: #f1f5f9;
}

.container-leaders .content-list header .top-header .inputs-leaders {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.container-leaders
  .content-list
  header
  .top-header
  .inputs-leaders
  .form-input {
  width: 100%;
}

.container-leaders
  .content-list
  header
  .top-header
  .inputs-leaders
  .form-input
  label {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: 400;
}

.container-leaders .content-list table tr th:first-child,
.container-leaders .content-list table tr td:first-child {
  padding-left: 16px;
}
