.content-selfs {
  margin: 1.5rem 0;
  max-height: 55vh;
  overflow: auto;
  width: 100%;
}

.title-selfs {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 16px;
  line-height: inherit;
  color: #333;
  border: 0;
}

.list-selfs {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(8,minmax(8rem,1fr));
  padding: 0.75rem 0;
  width: 100%;
}

.card-image {
  aspect-ratio: 1/1;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.button-card-image {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: hsla(0,0%,100%,.5);
  border: 0;
  border-radius: 1rem;
  position: absolute;
  right: 4px;
  top: 4px;
}

.image-selfie {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.icon-more {
  font-size: 18px !important;
  color: #0d99ff !important;
}

.input-selfie {
  text-align: center;
}

.label-file {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;

  aspect-ratio: 1/1;
  border: 0.375rem dotted #0d99ff;
  border-radius: 0.5rem;
  width: 100%;
}

.input-file {
  display: none !important;
}