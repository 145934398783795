.container-fields {
  width: 100%;
  padding: 24px;
}

.container-fields .content-fields {
  padding: 16px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container-fields .content-fields .fields {
  width: 100%;
  padding: 0 16px;
}

.container-fields .content-fields .fields .not-content {
  color: #9ca3af;
}

.container-fields .content-fields .fields .badge-field {
  display: inline-flex;
  align-items: center;
  padding: 2px 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
}

.container-fields .content-fields .fields .badge-field.badge-field-info {
  background-color: #eef2ff;
  color: #4f46e5;
}

.container-fields .content-fields .fields .badge-field.badge-field-success {
  background-color: #f0fdf4;
  color: #16a34a;
}

.container-fields .content-fields .fields .badge-field.badge-field-danger {
  background-color: #fef2f2;
  color: #dc2626;
}
