.banner-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.banner-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.banner-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.banner-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.banner-step label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 600px;
  border-radius: 16px;
  background-color: #e2e2e2;
  padding: 24px 80px 56px;
  cursor: pointer;
}

.banner-step label strong {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
}

.banner-step label > div {
  position: relative;
  width: 100%;
}

.banner-step label > div > .area-image {
  width: 100%;
  height: 124px;
  overflow: hidden;
  border: 2px solid #9747ff7a;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-step label > div > .area-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-step label > div strong {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

.banner-step label > div span {
  font-size: 16px;
  color: #4b5563;
  font-weight: 400;
}

.banner-step label > div .icon {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  height: 64px;
  width: 64px;
  border-radius: 100%;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-step > button {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.banner-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.banner-step button:not(:disabled):hover {
  background-color: #6f18bf;
}
