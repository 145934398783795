/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,700&display=swap"); */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
}

.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ecf0f1;
}

.content-login {
  background-color: #fff;
  border-radius: 15px;
  min-width: 960px;
  min-height: 40%;
  padding: 32px 0px 32px 0px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.content-login::before {
  content: "";
  position: absolute;
  background-color: #613BCE;
  width: 40%;
  height: 100%;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  left: 0;
}

.title-login {
  font-size: 28px;
  font-weight: bold;
  /*text-transform: capitalize;*/
}

.title-primary-login {
  color: #fff;
}

.title-second-login {
  color: #613BCE;
}

.description-login {
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
}

.description-primary-login {
  color: #fff;
}

.description-second-login {
  color: #7f8c8d;
}

.btn-login {
  border-radius: 15px;
  text-transform: uppercase;
  color: #fff;
  font-size: 10px;
  padding: 10px 50px;
  cursor: pointer;
  font-weight: bold;
  width: 150px;
  align-self: center;
  border: none;
  margin-top: 1rem;
}

.btn-primary-login {
  background-color: transparent;
  border: 1px solid #fff;
  transition: background-color 0.5s;
}

.btn-primary-login:hover {
  background-color: #fff;
  color: #58af9b;
}

.btn-second-login {
  background-color: #613BCE;
  border: 1px solid #613BCE;
  transition: background-color 0.5s;
}

.btn-second-login:hover {
  background-color: #fff;
  border: 1px solid #613BCE;
  color: #613BCE;
}

.first-content-login {
  display: flex;
}

.first-content-login .second-column-login {
  z-index: 11;
}

.first-column-login {
  text-align: center;
  width: 40%;
  z-index: 10;
}

.second-column-login {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-media-login {
  margin: 1rem 0;
}

.link-social-media-login:not(:first-child) {
  margin-left: 10px;
}

.link-social-media-login .item-social-media-login {
  transition: background-color 0.5s;
}

.link-social-media-login :hover .item-social-media-login {
  background-color: #58af9b;
  color: #fff;
  border-color: #58af9b;
}

.list-social-media-login {
  display: flex;
  list-style-type: none;
}

.item-social-media-login {
  border: 1px solid #bdc3c7;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #95a5a6;
}

.form-login {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.form-login input {
  height: 45px;
  width: 100%;
  border: none;
  background-color: #ecf0f1;
  margin-left: 20px;
}

input.login:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ecf0f1 inset !important;
  -webkit-text-fill-color: #000 !important;
}

.label-input-login {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  margin: 8px;
}

.label-input-login select {
  background-color: #ecf0f1;
  display: flex;
  align-items: center;
  margin: 8px;
  border: none;
  width: 100%;
}

.icon-modify-login {
  color: #7f8c8d;
  padding: 0 10px;
}

/* second content*/

.second-content-login {
  position: absolute;
  display: flex;
}

.second-content-login .first-column-login {
  order: 2;
  z-index: -1;
}

.second-content-login .second-column-login {
  order: 1;
  z-index: -1;
}

.password-login {
  color: #34495e;
  font-size: 14px;
  margin: 15px 0;
  text-align: center;
}

.password-login ::first-letter {
  text-transform: capitalize;
}

.sign-in-js-login .first-content-login .first-column-login {
  z-index: -1;
}

.sign-in-js-login .second-content-login .second-column-login {
  z-index: 11;
}

.sign-in-js-login .second-content-login .first-column-login {
  z-index: 13;
}

.sign-in-js-login .content-login ::before {
  left: 60%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  animation: slidein 1.3s;
  /*MODIFIQUEI DE 3s PARA 1.3s*/
  z-index: 12;
}

.sign-up-js-login .content-login ::before {
  animation: slideout 1.3s;
  /*MODIFIQUEI DE 3s PARA 1.3s*/
  z-index: 12;
}

.sign-up-js-login .second-content-login .first-column-login,
.sign-up-js-login .second-content-login .second-column-login {
  z-index: -1;
}

.sign-up-js-login .first-content-login .second-column-login {
  z-index: 11;
}

.sign-up-js-login .first-content-login .first-column-login {
  z-index: 13;
}

/* DESLOCAMENTO CONTEÚDO ATRÁS DO CONTENT:BEFORE*/

.sign-in-js-login .first-content-login .second-column-login {
  z-index: -1;
  position: relative;
  animation: deslocamentoEsq 1.3s;
  /*MODIFIQUEI DE 3s PARA 1.3s*/
}

.sign-up-js .second-content .second-column-login {
  position: relative;
  z-index: -1;
  animation: deslocamentoDir 1.3s;
  /*MODIFIQUEI DE 3s PARA 1.3s*/
}

/*ANIMAÇÃOO CSS PARA O CONTEÚDO*/

@keyframes deslocamentoEsq {
  from {
    left: 0;
    opacity: 1;
    z-index: 12;
  }
  25% {
    left: -80px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }
  50% {
    left: -100px;
    opacity: 0.2;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }
  to {
    left: -110px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes deslocamentoDir {
  from {
    left: 0;
    z-index: 12;
  }
  25% {
    left: 80px;
    /* z-index: 12;  NÃO HÁ NECESSIDADE*/
  }
  50% {
    left: 100px;
    /* z-index: 12; NÃO HÁ NECESSIDADE*/
    /* background-color: yellow;  Exemplo que dei no vídeo*/
  }
  to {
    left: 110px;
    z-index: -1;
  }
}

/*ANIMAÇÃO CSS*/

@keyframes slidein {
  from {
    left: 0;
    width: 40%;
  }
  25% {
    left: 5%;
    width: 50%;
  }
  50% {
    left: 25%;
    width: 60%;
  }
  75% {
    left: 45%;
    width: 50%;
  }
  to {
    left: 60%;
    width: 40%;
  }
}

@keyframes slideout {
  from {
    left: 60%;
    width: 40%;
  }
  25% {
    left: 45%;
    width: 50%;
  }
  50% {
    left: 25%;
    width: 60%;
  }
  75% {
    left: 5%;
    width: 50%;
  }
  to {
    left: 0;
    width: 40%;
  }
}
