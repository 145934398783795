.form-create-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-create-info .form-group {
  margin-bottom: 0;
}

.form-create-info .form-info {
  margin-bottom: 0;
}

.form-create-info .help-block {
  margin-bottom: 0;
  color: red;
}

.form-create-info .container-checkbox {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 0;
  width: fit-content;
}

.form-create-info .container-checkbox input[type="checkbox"] {
  margin: 0;
}

.map-container {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  height: 250px;
  margin-top: 8px;
}

.map-container .map-disabled {
  inset: 0;
  position: absolute;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.8);
}

.map-container .map-disabled span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.preview-image-info {
  position: relative;
  height: 80px;
  width: 80px;
  border-radius: 6px;
  overflow: hidden;
}

.preview-image-info img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.preview-image-info button {
  position: absolute;
  top: 4px;
  right: 4px;

  height: 20px;
  width: 20px;
  border: 0;
  background-color: #f3f4f6;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 99999px;
}

.container-images {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.container-images .content-images {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container-images .content-images .images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.container-images .content-images label {
  height: 80px;
  width: 80px;
  border-radius: 6px;

  background: #f3f4f6;
  border: 1px dashed #4b5563;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin: 0;
}
