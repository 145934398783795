.btn-drop {
  height: 40px;
  width: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #1e293b;
  border: none;
  border-radius: 50%; 
  background-color: transparent;

  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
}

.btn-drop:active {
  background-color: #e2e8f0;
}