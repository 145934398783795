.profile-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.profile-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.profile-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.profile-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.profile-step .field-profile-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.profile-step .field-profile-image label {
  position: relative;
  height: 162px;
  width: 162px;
  border: 2px solid #9747ff33;
  border-radius: 100%;
  background-color: #e2e2e2;
  cursor: pointer;
  overflow: hidden;
}

.profile-step .field-profile-image label img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.profile-step .field-profile-image label .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.profile-step .field-profile-image > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 6px;
}

.profile-step .field-profile-image > div strong {
  font-size: 20px;
  font-weight: 600;
  color: #9747ff;
}

.profile-step .field-profile-image > div span {
  font-size: 16px;
  color: #4b5563;
}

.profile-step > button {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.profile-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.profile-step button:not(:disabled):hover {
  background-color: #6f18bf;
}
