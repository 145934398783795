.container-infos {
  width: 100%;
  padding: 24px;
}

.container-infos .content-infos {
  padding: 16px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.container-infos .content-infos .infos {
  display: flex;
  width: 100%;
  padding: 0 16px;
}
