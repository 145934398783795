.modal-container {
  width: 100% !important;
  height: 100vh;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-guide-content {
  width: 80vw;
  height: 90vh;
  padding: 48px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.start-guide-content .content {
  flex: 1;
}

.start-guide-content header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-guide-content header strong {
  font-size: 18px;
  font-weight: 500;
  color: #9747ff;
}

.start-guide-content header button {
  display: flex;
  align-items: center;
  gap: 6px;

  background-color: transparent;
  border: 0;

  font-size: 16px;
  font-weight: 500;
  color: #9747ff;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.start-guide-content header button:disabled {
  color: #ccb1ff;
  cursor: not-allowed;
}

.start-guide-content header button:not(:disabled):hover {
  color: #6f18bf;
}

.start-guide-content footer {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 12px;
}

.start-guide-content footer div {
  height: 6px;
  border-radius: 9999px;
  background-color: #f5f5f5;
}

.start-guide-content footer div.filled {
  background-color: #9747ff;
}
