.political-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.political-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.political-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.political-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.political-step .field-text {
  width: 100%;
  max-width: 456px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 56px;
  padding: 0 12px;
  border: 2px solid #9747ff33;
  border-radius: 8px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.political-step .field-text:focus-within {
  border-color: #9747ff;
}

.political-step .field-text input {
  flex: 1;
  font-size: 18px;
  border: 0;
  background-color: transparent;
  color: #111827;
}

.political-step .field-text input::placeholder {
  color: #9ca3af;
}

.political-step > button {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.political-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.political-step > button:not(:disabled):hover {
  background-color: #6f18bf;
}

.field-select {
  position: relative;
  width: 100%;
  max-width: 540px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.field-select .select-container {
  width: 100%;
  position: relative;
  margin-top: 4px;
}

.field-select .select-button {
  all: unset;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  height: 56px;
  padding: 0 12px;
  border: 2px solid #9747ff33;
  border-radius: 8px;
  outline: none;
  color: #111827;
  font-size: 16px;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.field-select .select-button .placeholder {
  color: #9ca3af;
}

.field-select .select-button:hover {
  background-color: #fff;
}

.field-select .select-options {
  overflow: auto;
  max-height: 300px;
  position: absolute;
  padding: 8px 0;
  margin-top: 4px;
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.field-select .select-options .select-option {
  padding: 6px 32px 6px 16px;
  cursor: default;
  user-select: none;
  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.field-select .select-options .select-option:hover,
.field-select .select-options .select-option.selected {
  background-color: #9747ff;
  color: #fff;
}