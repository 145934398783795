.tutorial-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.tutorial-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.tutorial-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.tutorial-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.tutorial-step iframe {
  border-radius: 8px;
}

.tutorial-step > button {
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tutorial-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.tutorial-step button:not(:disabled):hover {
  background-color: #6f18bf;
}
