.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 15px;
}

.gallery-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 150px;
  text-align: center;
}

.gallery-image {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-bottom: 1px solid #ccc;
}

.gallery-description {
  padding-top: 10px;
  font-size: 12px;
  color: #333;
}

.gallery-user {
  font-size: 10px;
  color: #9A9A9A;
}