.infos-filters {
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.infos-filters > * {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.infos-filters label {
  font-size: 14px;
  color: #9a9a9a;
  font-weight: 400;
}

.infos-filters .info-input-title {
  border-color: #cccccc;
  height: 38px;
}

@media (max-width: 712px) {
  .infos-filters {
    flex-direction: column;
  }
}
