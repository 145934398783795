.info-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    padding: 20px;
}

.info-box-header {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
}

.info-box-header>p {
    font-size: 25px;
    line-height: 25px;
}

.info-box-header>p>span:last-child {
    font-size: 20px;
    font-weight: 100;
}

.info-box-descripion {
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
}

.info-box-descripion p {
    font-size: 15px;
    line-height: 25px;
}

.info-box div>p>strong {
    margin-right: 5px;
}

.info-box div:first-child {
    padding-right: 10px;
    margin-right: 10px;
}

.result-box {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    padding: 20px;
}

.btn-back {
    margin-top: -10px;
}