.bread-crumb {
  padding-top: 5px;
}

.div-buttons {
  margin-top: 21px;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.select-candidates {
  margin-top: 8px;
}

.filters {
  margin: 32px 0px 24px;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 4px;

  position: absolute;
  top: 16px;
  right: 30px;
}

.buttons-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
  margin-bottom: 10px;
  gap: 4px;
}


.btn-reset {
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #94a3b8;
  border-radius: 4px;

  font-weight: 500;
  line-height: 24px;
  color: #475569;
  cursor: pointer;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-reset:hover {
  background-color: #f1f5f9;
}

.btn-leadership-request {
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #94a3b8;
  border-radius: 4px;

  display: flex;
  align-items: center;
  gap: 8px;

  line-height: 24px;
  color: #475569;
  cursor: pointer;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-leadership-request:hover {
  background-color: #f1f5f9;
}

.btn-action {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;

  background-color: #16a34a;
  color: white;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-action.search {
  background-color: #613BCE;
}

.btn-action:hover {
  color: white;
  background-color: #15803d;
}

.btn-action.search:hover {
  color: white;
  background-color: #2563eb;
}

.badge-custom {
  background-color: #e2e8f0;
  color: #475569;
}

.badge-primary {
  background-color: #0d6efd;
  color: #ffffff;
}

.badge-primary::first-letter {
  text-transform: uppercase;
}

.badge-success {
  background-color: #198754;
  color: #ffffff;
}

.badge-success::first-letter {
  text-transform: uppercase;
}

.badge-danger {
  background-color: #dc3545;
  color: #ffffff;
}

.badge-danger::first-letter {
  text-transform: uppercase;
}