.form-create-group {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-create-group .form-group {
  margin-bottom: 0;
}

.form-create-group .radio {
  margin-top: 4px;
}

.form-create-group .help-block {
  margin-bottom: 0;
  color: red;
}

.form-create-group .container-radio {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
}

.form-create-group .container-radio input[type="radio"] {
  margin: 0;
}

.help-text {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.help-text span {
  font-size: 12px;
  color: #6b7280;
}

.preview_image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.preview_image button {
  position: absolute;
  top: 4px;
  right: 4px;

  height: 20px;
  width: 20px;
  border: 0;
  background-color: #f3f4f6;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 99999px;
}

.container-tags {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.container-tags .content-tags {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container-tags .content-tags .tags {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}

.container-tags .content-tags > button {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  width: fit-content;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 8px 12px;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;

  font-size: 14px;
  font-weight: 500;
  color: #374151;

  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.container-tags .content-tags > button:hover:not(:disabled) {
  background-color: #f9fafb;
}
