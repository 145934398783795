.btn-reset:hover {
  background-color: #f1f5f9;
}

.btn-search {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;

  background-color: #613BCE;
  color: white;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-create {
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;

  background-color: #1DC7EA;
  color: white;
  font-weight: 500;
  line-height: 24px;

  cursor: pointer;
  white-space: nowrap;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.btn-create:hover {
  color: white;
  background-color: #0e4aca;
}

.btn-search:hover {
  color: white;
  background-color: #2563eb;
}

.profile {
  display: flex;
  flex-direction: column;
}

.profile strong {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  display: flex;
  align-items: center;
  gap: 8px;
}

.profile .email {
  color: #94a3b8;
}

.badge-custom-success {
  background-color: #bbf7d0;
  color: #16a34a;
}

.not-found {
  width: 100%;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  background-color: #f3f4f6;
  color: #475569;

  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}