.container-icons {
  position: absolute;
  z-index: 2000;
  padding: 16px 8px;
  width: 100%;
  max-width: 300px;
  max-height: 400px;
  overflow: auto;
  margin-top: 8px;

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;

  display: flex;
  flex-direction: column;
  gap: 12px;
}

.container-icons input {
  border-radius: 4px;
}

.grid-icons {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  gap: 4px;
}

.trigger-icon {
  all: unset;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #e2e8f0;
  padding: 4px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.trigger-icon:hover {
  border-color: #475569;
}

.trigger-icon.selected {
  background-color: #475569;
}

.empty-state {
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trigger-button {
  width: 100%;

  background-color: #fff;
  padding: 8px 12px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #e3e3e3;
  border-radius: 4px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.trigger-button:disabled {
  background-color: #f3f4f6;
  border-color: #f3f4f6;
}

.trigger-button:disabled span {
  color: #9ca3af;
}

.trigger-button span {
  color: #565656;
}

.selected-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}
