.container-info {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.content-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.toggle-menu {
  display: flex;
  align-items: center;
  gap: 12px;
}

.content-info .content-info-image {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
}

.content-info .content-info-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.content-info .content-info-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-info .content-info-data .infos-badges {
  display: inline-flex;
  gap: 6px;
}

.content-info .content-info-data div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content-info .content-info-data div:first-child strong {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #0f172a;
}

.custom-badge {
  background-color: #e2e8f0;
  color: #475569;
}

@media (max-width: 712px) {
  .content-info .content-info-image {
    height: 24px;
    width: 24px;
  }

  .content-info .content-info-data div:first-child strong {
    font-size: 12px;
  }

  .content-info .content-info-data {
    gap: 4px;
  }

  .content-info .content-info-data .infos-badges {
    display: none;
  }

  .content-info .content-info-data div span {
    display: none;
  } 
}
