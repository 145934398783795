.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox input {
  display: none;
}

.custom-checkbox .checkmark {
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #514e4e;
  border-radius: 999px;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 3px;
}

.custom-checkbox.checked .checkmark {
  border-color: inherit;
}

.fa-check {
  font-size: 13px;
}

.fa-check:before {
  color: #FFFFFF;
  font-size: 13px;
  border-radius: 999px;
}