.form-associate-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.checkbox-tag {
  display: none;
}

.checkbox-tag:checked + label {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: #fff;
}

.checkbox-tag + label {
  width: fit-content;
  padding: 6px 12px;
  border: 1px solid #9ca3af;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 500;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
