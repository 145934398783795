.colors-step {
  height: 100%;
  width: 100%;
  padding: 24px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.colors-step .heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.colors-step .heading strong {
  font-size: 28px;
  font: 600;
  color: #111827;
}

.colors-step .heading span {
  font-size: 16px;
  font-weight: 500;
  color: #374151;
}

.colors-step .steps {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10%;
}

.colors-step .steps > div {
  position: relative;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background-color: #9747ff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px 0px #00000012;
}

.colors-step .steps > div.disabled {
  background-color: #cccccc;
}

.colors-step .steps span {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.colors-step .steps hr {
  width: 200px;
  border: none;
  border-top: 6px dotted #f2f2f2;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

.colors-step .steps > div > .input-color {
  position: absolute;
  bottom: 0;
  transform: translateY(120%);
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.colors-step .steps > div > .input-color strong {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

.colors-step .steps > div.disabled .input-color strong {
  color: #cccccccc;
}

.colors-step .steps > div > .input-color label {
  display: block;
  margin: 0;
  margin-top: 12px;
  width: 160px;
  border-radius: 9999px;
  padding: 8px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px 0px #0000000d;
}

.colors-step .steps > div > .input-color input {
  align-self: flex-start;
  visibility: hidden;
  margin-top: -24px;
}

.colors-step .steps > div > .input-color label div {
  height: 12px;
  width: 100%;
  border-radius: 9999px;
}

.colors-step .steps > div.disabled > .input-color label div {
  background: #f2f2f287;
}

.colors-step > button {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: auto;
  padding: 12px 20px;
  border: 0;
  border-radius: 8px;
  background-color: #9747ff;
  color: #fff;
  font-weight: 500;
  font-size: 18px;

  transition-property: color, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.colors-step > button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
}

.colors-step button:not(:disabled):hover {
  background-color: #6f18bf;
}

.colors-step .animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
